/*!
 * CookieBar 
 * David MOTTET
 */

(function (window) {
	'use strict';

	var document = window.document, 

		cookieShop = {},


		flavor = "chocolateCookie",
		//text
		frosting = "<p class='cookies-text'>En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies conformément à notre politique de données personnelles.</p>",
		cnil = "<a href='http://www.cnil.fr/vos-obligations/sites-web-cookies-et-autres-traceurs/que-dit-la-loi/' class='cookies-link' target='_blank' title='Article CNIL'>Lire l'article CNIL</a>",
		button = "<span class='cookies-button'>OK</span>",

	init = function () {
		var brownie = tasteCookie(flavor);
		if(brownie === null) {
			showCookies();
		}
	},

	cookCookie = function (_flavor, _hunger, _size) {
		if (_size) {
			var date = new Date();
			date.setTime(date.getTime()+(_size*24*60*60*1000));
			var expires = "; expires="+date.toGMTString();
		}else{
			var expires = "";
		}
		document.cookie = _flavor+"="+_hunger+expires+"; path=/";
	},

	tasteCookie = function (_flavor) {
		var flavorEQ = _flavor + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(flavorEQ) == 0) return c.substring(flavorEQ.length,c.length);
		}
		return null;
	},

	showCookies = function () {

		var cookie = document.createElement("div");
		cookie.textContent = frosting;
		cookie.id = "cookies";
		cookie.className = "cookies";

		cookie.innerHTML = frosting+cnil+button;

		var table = document.getElementsByTagName("body");
		table[0].appendChild(cookie);

		cookie.addEventListener("click", function () {
			cookCookie(flavor,true,390);
		});
	};

	cookieShop.init = init;
	
	window.cookieShop = cookieShop;
	return cookieShop;
})(this);
